import * as React from 'react'
import styled from 'styled-components'
import Container from '../components/Container'
import SmallBanner from '../components/SmallBanner'

const Wrapper = styled.form`
  display: grid;
  gap: 1rem;
  margin-top: 3rem;
`
const StyledLabel = styled.label`
  display: grid;

  input, select {
    padding: 0.5rem;
  }
`
const RadioLabel = styled.label`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  `

const OrderPage = () => {
  return (
    <>
      <SmallBanner>
          <h1>Objednávka</h1>
        </SmallBanner>
      <Container>
        <Wrapper method="post" action="#">
          <StyledLabel>
            Email*
            <input type="email" name="orderEmail" id="orderEmail" required />
          </StyledLabel>
          <StyledLabel>
            Kraj, ze kterého pocházíte/ adresa*
            <input type="text" name="orderAddress" id="orderAddress" required/>
          </StyledLabel>
          <p>Věk cestujících*</p>
          <RadioLabel>
            18-30
            <input type="radio" name="orderAge" id="orderAge1" required/>
          </RadioLabel>
          <RadioLabel>
            30-45
            <input type="radio" name="orderAge" id="orderAge2" required/>
          </RadioLabel>
          <RadioLabel>
            45-65
            <input type="radio" name="orderAge" id="orderAge3" required/>
          </RadioLabel>
          <RadioLabel>
            65+
            <input type="radio" name="orderAge" id="orderAge4"  />
          </RadioLabel>
          <StyledLabel>
            Maximální celkový rozpočet (za všechny osoby)*
            <input type="text" name="orderBudget" id="orderBudget" required/>
          </StyledLabel>
          <StyledLabel>
          Celkový počet osob*
            <input type="text" name="orderTotalCount" id="orderTotalCount" required/>
          </StyledLabel>
          <StyledLabel>
            Děti do 12 let*
            <input type="number" name="orderMinorCount" id="orderMinorCount" required/>
          </StyledLabel>
          <StyledLabel>
            Děti do 2 let*
            <input type="number" name="orderBabyCount" id="orderBabyCount" required/>
          </StyledLabel>
          <StyledLabel>
            Ideální počet dnů dovolené*
            <input type="number" name="orderDuration" id="orderDuration" required/>
          </StyledLabel>
          <StyledLabel>
            Ideální termín dovolené*
            <input type="number" name="orderDate" id="orderDate" required/>
          </StyledLabel>
          <p>Jaká by měla být Vaše dovolená?*</p>
          <RadioLabel>
            Poznávací
            <input type="radio" name="orderType" id="orderType1" required/>
          </RadioLabel>
          <RadioLabel>
            Pobytová
            <input type="radio" name="orderType" id="orderType2" required/>
          </RadioLabel>
          <RadioLabel>
            Kombinace
            <input type="radio" name="orderType" id="orderType3" required/>
          </RadioLabel>
          <p>Která lokalita Vás zajímá?*</p>
          <RadioLabel>
            Česko
            <input type="radio" name="orderLocation" id="orderLocation1" required/>
          </RadioLabel>
          <RadioLabel>
            Evropa
            <input type="radio" name="orderLocation" id="orderLocation2" required/>
          </RadioLabel>
          <RadioLabel>
            Zbytek světa
            <input type="radio" name="orderLocation" id="orderLocation3" required/>
          </RadioLabel>
          <p>Kde chcete svou dovolenou strávit?*</p>
          <RadioLabel>
            Město
            <input type="radio" name="orderLocationType" id="orderLocationType1" required/>
          </RadioLabel>
          <RadioLabel>
            Moře
            <input type="radio" name="orderLocationType" id="orderLocationType2" required/>
          </RadioLabel>
          <RadioLabel>
            Hory
            <input type="radio" name="orderLocationType" id="orderLocationType3" required/>
          </RadioLabel>
          <RadioLabel>
            Jiné
            <input type="radio" name="orderLocationType" id="orderLocationType4" required/>
          </RadioLabel>
          <p>Preferovaná forma dopravy do destinace*</p>
          <RadioLabel>
            Letadlo
            <input type="radio" name="orderTransportType" id="orderTransportType1" required/>
          </RadioLabel>
          <RadioLabel>
            Vlak
            <input type="radio" name="orderTransportType" id="orderTransportType2" required/>
          </RadioLabel>
          <RadioLabel>
            Autobus
            <input type="radio" name="orderTransportType" id="orderTransportType3" required/>
          </RadioLabel>
          <RadioLabel>
            Vlastní
            <input type="radio" name="orderTransportType" id="orderTransportType4" required/>
          </RadioLabel>
          <StyledLabel>
            Kolik hodin jste ochotni strávit na cestě do destinace?*
            <select id="orderTravelTime" name="orderTravelTime" required>
              <option value="Do 5 hodin">Do 5 hodin</option>
              <option value="Do 10 hodin">Do 10 hodin</option>
              <option value="Do 20 hodin">Do 20 hodin</option>
              <option value="Do 30 hodin">Do 30 hodin</option>
              <option value="Do 40 hodin">Do 40 hodin</option>
              <option value="Více">Více</option>
            </select>
          </StyledLabel>
          <p>Preferovaný charakter dovolené*</p>
          <RadioLabel>
            Klidná
            <input type="radio" name="orderVacationType" id="orderVacationType1" required/>
          </RadioLabel>
          <RadioLabel>
            Rušná
            <input type="radio" name="orderVacationType" id="orderVacationType2" required/>
          </RadioLabel>
          <RadioLabel>
            Něco mezi tím
            <input type="radio" name="orderVacationType" id="orderVacationType3" required/>
          </RadioLabel>
          <StyledLabel>
            Míra dobrodružství, kterou chcete podstoupit (5 je nejdobrodružnější cesta)*
            <select id="orderAdventure" name="orderAdventure" required>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </StyledLabel>
          <StyledLabel>
            Co je pro Vás rozhodující při výběru ubytování (vyjma ceny)?*
            <input type="text" name="orderAccommodation" id="orderAccommodation" required/>
          </StyledLabel>
          {<StyledLabel>
            Čím je pro Vás cestování především?*
            <input type="text" name="orderMotivation" id="orderMotivation" required/>
          </StyledLabel>}
          <StyledLabel>
            S čím se na dovolené nechcete potkat?*
            <input type="text" name="orderAvoid" id="orderAvoid" required/>
          </StyledLabel>
          <StyledLabel>
            O čem by měla ideálně Vaše příští dovolená být?*
            <input type="text" name="orderTheme" id="orderTheme" required/>
          </StyledLabel>
          <StyledLabel>
            Jaká je nejvzdálenější země, kterou jste navštívil/a?*
            <input type="text" name="orderDistance" id="orderDistance" required/>
          </StyledLabel>
          <StyledLabel>
            Jaká destinace Vás nejvíce zklamala?*
            <input type="text" name="orderDisappointment" id="orderDisappointment" required/>
          </StyledLabel>
          <StyledLabel>
            Jaké jsou Vaše největší koníčky?*
            <input type="text" name="orderHobbies" id="orderHobbies" required/>
          </StyledLabel>
          <StyledLabel>
            Jaký je Váš oblíbený sport?*
            <input type="text" name="orderSport" id="orderSport" required/>
          </StyledLabel>
          <StyledLabel>
            Jaký je Váš oblíbený sport?*
            <input type="text" name="orderSport" id="orderSport" required/>
          </StyledLabel>
          <StyledLabel>
            Jaký je Váš nejoblíbenější film/filmy?*
            <input type="text" name="orderFilm" id="orderFilm" required/>
          </StyledLabel>
          <StyledLabel>
            Jaká je Vaše oblíbená kniha/knihy?*
            <input type="text" name="orderBook" id="orderBook" required/>
          </StyledLabel>
          <StyledLabel>
            A co hudební kapela?*
            <input type="text" name="orderBand" id="orderBand" required/>
          </StyledLabel>
          <StyledLabel>
            Které nejlepší jídlo jste na cestách ochutnali?*
            <input type="text" name="orderFood" id="orderFood" required/>
          </StyledLabel>
          <StyledLabel>
            A čeho se rádi na cestách napijete?*
            <input type="text" name="orderDrink" id="orderDrink" required/>
          </StyledLabel>
          <StyledLabel>
            Kdybyste se s kýmkoliv mohli setkat osobně, kdo by to byl?*
            <input type="text" name="orderIdol" id="orderIdol" required/>
          </StyledLabel>
          <p>Cestování hromadnými prostředky v cílové destinaci berete spíše jako:*</p>
          <RadioLabel>
            Utrpení
            <input type="radio" name="orderLocalTransport" id="orderLocalTransport1" required/>
          </RadioLabel>
          <RadioLabel>
            Zkušenost
            <input type="radio" name="orderLocalTransport" id="orderLocalTransport2" required/>
          </RadioLabel>
          <RadioLabel>
            Nemůžu se toho dočkat!
            <input type="radio" name="orderLocalTransport" id="orderLocalTransport3" required/>
          </RadioLabel>
          <button type="submit">Send</button>
          <input type="reset" value="Clear" />
        </Wrapper>
      </Container>
    </>
  )
}

export default OrderPage